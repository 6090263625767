<script lang="ts">
  import clsx from "clsx";

  export let showImage: boolean;
</script>

<div
  class={clsx(
    "p-6 bg-white/5 border border-white/10 rounded-xl",
    showImage ? "with-image" : "",
  )}
>
  <div class="flex gap-x-4 items-center text-center mb-6">
    <slot name="icon" />
    <div class="type-title-md">
      <slot name="title" />
    </div>
  </div>
  <div class="space-y-2 text-white/60">
    <slot />
  </div>
</div>

<style lang="postcss">
  .with-image {
    background-image: url("$lib/assets/marketing/landing-bg-boxes.svg");
    background-position: right;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
</style>
